import $ from 'cash-dom'
import { getUserZipCode } from '../utilities/user-zip-code'
import { queryNarvarShipping } from '../resources/narvar'

if (Pura?.template?.name === 'product') {
  const hasEstimatedDeliveryWidget =
    $('[data-component="estimated-delivery-date"]').length > 0 || false

  if (hasEstimatedDeliveryWidget) {
    const zoneData = {
      'South-B': 4.6,
      'Pacific': 5.1,
      'Northeast-C': 4.5,
      'Northeast-D': 4.4,
      'Southeast-A': 4.2,
      'Southeast-B': 3.7,
      'Southeast-C': 3.9,
      'Midwest-A': 4.5,
      'Midwest-B': 4.6,
      'Midwest-C': 4.9,
      'South-A': 4.4,
      'Mountain-A': 4.9,
      'Mountain-B': 4.8,
      'West-A': 4.6,
      'West-B': 5.4,
      'Northeast-B': 4.8,
      'Northeast-A': 4.6,
    }

    const subZoneData = {
      '391': 3.9,
      '840': 4.5,
      '970': 4.8,
      '971': 4.9,
      '972': 4.7,
      '973': 4.8,
      '976': 5.6,
      '977': 4.9,
      '978': 5.2,
      '979': 4.9,
      '982': 5.3,
      '984': 4.7,
      '985': 5.2,
      '986': 4.6,
      '988': 5.3,
      '989': 5.5,
      '990': 4.9,
      '993': 4.9,
      '994': 4.4,
      '995': 6,
      '996': 6.8,
      '997': 6.5,
      '998': 6.3,
      '999': 7.7,
    }

    const zoneLookup = {
      '00': 'Northeast-A',
      '01': 'Northeast-A',
      '02': 'Northeast-A',
      '03': 'Northeast-A',
      '04': 'Northeast-A',
      '05': 'Northeast-A',
      '06': 'Northeast-A',
      '07': 'Northeast-B',
      '08': 'Northeast-B',
      '09': 'Northeast-B',
      '10': 'Northeast-C',
      '11': 'Northeast-C',
      '12': 'Northeast-C',
      '13': 'Northeast-C',
      '14': 'Northeast-D',
      '15': 'Northeast-D',
      '16': 'Northeast-D',
      '17': 'Northeast-D',
      '18': 'Northeast-D',
      '19': 'Northeast-D',
      '20': 'Southeast-A',
      '21': 'Southeast-A',
      '22': 'Southeast-A',
      '23': 'Southeast-A',
      '24': 'Southeast-A',
      '25': 'Southeast-A',
      '26': 'Southeast-A',
      '27': 'Southeast-B',
      '28': 'Southeast-B',
      '29': 'Southeast-B',
      '30': 'Southeast-C',
      '31': 'Southeast-C',
      '32': 'Southeast-C',
      '33': 'Southeast-C',
      '34': 'Southeast-C',
      '35': 'Southeast-C',
      '36': 'Southeast-C',
      '37': 'Southeast-C',
      '38': 'Southeast-C',
      '39': 'Southeast-C',
      '40': 'Midwest-A',
      '41': 'Midwest-A',
      '42': 'Midwest-A',
      '43': 'Midwest-A',
      '44': 'Midwest-A',
      '45': 'Midwest-A',
      '46': 'Midwest-A',
      '47': 'Midwest-A',
      '48': 'Midwest-A',
      '49': 'Midwest-A',
      '50': 'Midwest-B',
      '51': 'Midwest-B',
      '52': 'Midwest-B',
      '53': 'Midwest-B',
      '54': 'Midwest-B',
      '55': 'Midwest-B',
      '56': 'Midwest-B',
      '57': 'Midwest-B',
      '58': 'Midwest-B',
      '59': 'Midwest-B',
      '60': 'Midwest-C',
      '61': 'Midwest-C',
      '62': 'Midwest-C',
      '63': 'Midwest-C',
      '64': 'Midwest-C',
      '65': 'Midwest-C',
      '66': 'Midwest-C',
      '67': 'Midwest-C',
      '68': 'Midwest-C',
      '69': 'Midwest-C',
      '70': 'South-A',
      '71': 'South-A',
      '72': 'South-A',
      '73': 'South-A',
      '74': 'South-A',
      '75': 'South-A',
      '76': 'South-A',
      '77': 'South-A',
      '78': 'South-B',
      '79': 'South-B',
      '80': 'Mountain-A',
      '81': 'Mountain-A',
      '82': 'Mountain-A',
      '83': 'Mountain-A',
      '84': 'Mountain-B',
      '85': 'Mountain-B',
      '86': 'Mountain-B',
      '87': 'Mountain-B',
      '88': 'Mountain-B',
      '89': 'Mountain-B',
      '90': 'West-A',
      '91': 'West-A',
      '92': 'West-A',
      '93': 'West-A',
      '94': 'West-B',
      '95': 'West-B',
      '96': 'West-B',
      '97': 'Pacific',
      '98': 'Pacific',
      '99': 'Pacific',
    }

    const getZone = (zip: string) => {
      const firstTwoDigits = zip.substring(0, 2)
      return zoneLookup[firstTwoDigits] || 'Default'
    }

    const shippingDuration = (zipCode: string) => {
      const handling = Number(Pura?.estimatedDelivery?.handling) || 0
      const buffer = Number(Pura?.estimatedDelivery?.buffer) || 0

      const normalizedZip = String(zipCode).padStart(5, '0')
      const prefix = normalizedZip.substring(0, 3)

      if (subZoneData[prefix] !== undefined) {
        return subZoneData[prefix] + handling + buffer
      }

      const zone = getZone(normalizedZip)
      const baseDays = zoneData[zone] || zoneData['West-B']
      return baseDays + handling + buffer
    }

    const getEstimatedShipDate = (zip: string) => {
      const days = shippingDuration(zip)
      const now = new Date()

      const estFormatter = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/New_York',
        hour: 'numeric',
        hour12: false,
      })

      const estHour = parseInt(estFormatter.format(now))
      const cutoffHour = Pura?.estimatedDelivery?.same_day_cut_off || 14

      const targetDate = new Date()
      const roundedDays = Math.round(days)

      if (estHour >= cutoffHour) {
        targetDate.setDate(targetDate.getDate() + roundedDays + 1)
      } else {
        targetDate.setDate(targetDate.getDate() + roundedDays)
      }

      const options = {
        weekday: 'long' as const,
        month: 'long' as const,
        day: '2-digit' as const,
      }
      const formatted = targetDate.toLocaleDateString('en-US', options)
      $('#estimate').html(
        `<p>Get it by ${formatted} if you choose <b>Standard Shipping</b></p>`
      )

      $('[data-component="estimated-delivery-date"]').removeClass('hidden')
      sessionStorage.setItem('user_zip_code', zip)
      queryNarvarShipping(zip)
    }

    $(function () {
      const zipCode = sessionStorage.getItem('user_zip_code')

      if (!zipCode) {
        getUserZipCode()
          .then((zip) => {
            if (zip && /^\d{5}$/.test(zip)) {
              $('#current-zip').text(zip)
              getEstimatedShipDate(zip)
            }
          })
          .catch(() => {
            console.warn('Could not get zip from ipapi')
          })
      } else {
        getEstimatedShipDate(zipCode)
        $('#current-zip').text(zipCode)
      }

      $('#toggle-zip').on('click', () => {
        $('#zip-form').toggleClass('hidden')
        $('#zip-arrow').text($('#zip-form').hasClass('hidden') ? '▼' : '▲')
      })

      $('#update-zip').on('click', () => {
        const newZip = ($('#zip-input').val() as string).trim()
        if (/^\d{5}$/.test(newZip)) {
          $('#current-zip').text(newZip)
          $('#zip-form').addClass('hidden')
          $('#zip-arrow').text('▼')

          getEstimatedShipDate(newZip)
        } else {
          alert('Please enter a valid 5-digit zip code')
        }
      })
    })
  }
}
