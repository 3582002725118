import $ from 'cash-dom'
import { getURLParam } from './toolkit'

if (Pura.product?.metafields?.redirect_to_product) {
  const urlParams = new URLSearchParams(window.location.search)
  const queryString = urlParams.toString()
  const redirectUrl = queryString
    ? `${Pura.product.metafields.redirect_to_product}?${queryString}`
    : Pura.product.metafields.redirect_to_product

  window.location.href = redirectUrl
}

if (Pura.template?.name === 'login') {
  const redirect = getURLParam('redirect')
  const resetEmail = getURLParam('reset_email')
  const checkoutUrl = getURLParam('checkout_url')

  if (redirect) {
    $('#account-login-redirect-panel').removeClass('hidden')
  } else if (resetEmail) {
    $('#password-reset-panel').removeClass('hidden')
  } else if (checkoutUrl) {
    window.location.href = '/#show-account-panel'
  } else {
    setTimeout(() => {
      window.location.hash = 'show-account-panel'
    }, 500)
  }
}
